import bep20Abi from "./abi/bep20.json";
import payAbi from "./abi/pay.json";
import { ethers } from "ethers";
import { PayAddr } from "./ContractAddress";

export const getProvider = () => {
  if (window.ethereum) {
    return new ethers.providers.Web3Provider(window.ethereum);
  } else if (window.BinanceChain) {
    return new ethers.providers.Web3Provider(window.BinanceChain);
  } else {
    return new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed1.defibit.io/"
    );
  }
};

function getSigner() {
  return getProvider().getSigner();
}

export const getBep20Contract = (address) => {
  return new ethers.Contract(address, bep20Abi).connect(getSigner());
};

export const getPayContract = () => {
  return new ethers.Contract(PayAddr, payAbi).connect(getSigner());
};
