const tokens = {
  usdt: {
    symbol: "USDT",
    address: "0xa11c8d9dc9b66e209ef60f0c8d969d3cd988782c",
    decimals: 18,
    projectLink: "https://tether.to/",
  },
  busd: {
    symbol: "BUSD",
    address: "0x8301f2213c0eed49a7e28ae4c3e91722919b8b47",
    decimals: 18,
    projectLink: "https://www.paxos.com/busd/",
  },
};

export default tokens;
