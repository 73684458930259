<template>
  <div id="preloader">
    <div class="preload-content">
      <div id="dream-load"></div>
    </div>
  </div>
</template>

<script></script>

<style scoped>
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2001;
  top: 0;
  left: 0;
  background: #4834d4;
  background: -webkit-linear-gradient(to right, #4834d4, #341f97);
  background: -webkit-linear-gradient(left, #4834d4, #341f97);
  background: linear-gradient(to right, #4834d4, #341f97);
}

.preload-content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -35px;
  margin-left: -35px;
  z-index: 100;
}

#dream-load {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 3px;
  border-style: solid;
  border-color: transparent;
  border-top-color: rgba(255, 255, 255, 0.5);
  -webkit-animation: dreamrotate 2400ms linear infinite;
  animation: dreamrotate 2400ms linear infinite;
  z-index: 999;
}

#dream-load:before {
  content: "";
  position: absolute;
  top: 7.5px;
  left: 7.5px;
  right: 7.5px;
  bottom: 7.5px;
  border-radius: 50%;
  border: 3px;
  border-style: solid;
  border-color: transparent;
  border-top-color: rgba(255, 255, 255, 0.5);
  -webkit-animation: dreamrotate 2700ms linear infinite;
  animation: dreamrotate 2700ms linear infinite;
}

#dream-load:after {
  content: "";
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border-radius: 50%;
  border: 3px;
  border-style: solid;
  border-color: transparent;
  border-top-color: rgba(255, 255, 255, 0.5);
  -webkit-animation: dreamrotate 1800ms linear infinite;
  animation: dreamrotate 1800ms linear infinite;
}

@-webkit-keyframes dreamrotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes dreamrotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
