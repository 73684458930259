import { ethers } from "ethers";

export default {
  shortAddress(address) {
    if (address) {
      const prefix = address.substr(0, 4);
      const suffix = address.substr(address.length - 3);
      return `${prefix}...${suffix}`;
    }
    return "";
  },

  /**
   * 保留4位小数
   * @param value
   * @returns {number|number}
   */
  toFixed(value) {
    value = Math.floor(value * 10000) / 10000;
    return value === 0 ? 0 : value;
  },

  /**
   * 人类可读的方式
   * @param bigNumberValue
   * @param decimals
   * @returns {string}
   */
  toReadable(bigNumberValue, decimals = 18) {
    if (bigNumberValue === 0 || bigNumberValue === null) return "0";
    return ethers.utils.formatUnits(bigNumberValue, decimals);
  },

  toBigNumber(strValue, decimals = 18) {
    return ethers.utils.parseUnits(strValue, decimals);
  },

  /**
   * 千分逗号显示
   * @param value
   * @returns {string}
   */
  commify(value) {
    return ethers.utils.commify(value);
  },
};
