<template>
  <div class="features-list">
    <div class="who-we-contant text-center">
      <img
        src="@/assets/img/icons/wallet.png"
        class="mb-15"
        style="width: 90px"
        alt=""
      />

      <h4 class="w-text mb-30" data-wow-delay="0.9s">Connect Your Wallet</h4>
      <ConnectWith
        v-for="wallet in wallets"
        :key="wallet"
        :connectWith="wallet.name"
        :img="require(`@/assets/${wallet.img}`)"
        @click="connectNetWallet(wallet)"
      >
      </ConnectWith>
    </div>
  </div>
</template>

<script setup>
import ConnectWith from "@/components/Helper/ConnectWith";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
const store = useStore();
const wallets = computed(() => store.getters["walletList"]);

const nodes = {
  56: {
    chainId: 56,
    chainName: "Binance Smart Chain Mainnet",
    rpcUrls: ["https://bsc-dataseed1.defibit.io/"],
    blockExplorerUrls: ["https://bscscan.com/"],
  },
  97: {
    chainId: 97,
    chainName: "Binance Smart Chain Testnet",
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
};
const defaultAccount = ref("");

const connectNetWallet = (wallet) => {
  switch (wallet.name) {
    case "MetaMask":
      connectToMetamask();
      break;
    case "WalletConnect":
      walletConnect();
      break;
    default:
      connectToBinanceWallet();
  }
};
const connectToMetamask = async () => {
  if (!window.ethereum) {
    // ElNotification.error({
    //   title: "Metamask",
    //   message: "Metamask not installed",
    // });
    return false;
  }
  // 连接到MetaMask
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  await setup(window.ethereum, accounts);
};

const walletConnect = async () => {
  const walletConnectProvider = new WalletConnectProvider({
    rpc: {
      56: nodes[56].rpcUrls[0],
      97: nodes[97].rpcUrls[0],
    },
  });
  try {
    const accounts = await walletConnectProvider.enable();
    await setup(walletConnectProvider, accounts);
  } catch (e) {
    // console.log("close", JSON.stringify(e));
  }
};

const connectToBinanceWallet = async () => {
  if (!window.BinanceChain) {
    // ElNotification.error({
    //   title: "Error",
    //   message: "Binance Chain Wallet not installed",
    // });
    return;
  }
  // 连接到币安钱包
  const accounts = await window.BinanceChain.request({
    method: "eth_requestAccounts",
  });
  await setup(window.BinanceChain, accounts);
};
const setup = async (provider, accounts) => {
  // const chainId = parseInt(provider.chainId, 16);
  console.log("process.env.VUE_APP_CHAIN_ID", process.env.VUE_APP_CHAIN_ID);
  const chainId = parseInt(process.env.VUE_APP_CHAIN_ID, 10);
  let currentNode = nodes[chainId];
  if (currentNode === undefined) {
    notifyUnSupportChainId();
    currentNode = nodes[97];
  }
  await provider.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: `0x${currentNode.chainId.toString(16)}`,
        chainName: currentNode.chainName,
        nativeCurrency: {
          name: "BNB",
          symbol: "bnb",
          decimals: 18,
        },
        rpcUrls: currentNode.rpcUrls,
        blockExplorerUrls: currentNode.blockExplorerUrls,
      },
    ],
  });
  // 监听事件
  if (!provider.isListening) {
    provider.on("accountsChanged", handleAccountsChanged);
    provider.on("chainChanged", handleChainChanged);
    provider.on("connect", handleConnect);
    provider.on("disconnect", handleDisconnect);
    // provider.on("message", this.handleMessage);
    provider.isListening = true;
  }
  ok(provider, accounts);
};

const ok = (provider, accounts) => {
  defaultAccount.value = accounts[0].toLowerCase();
  store.commit("setDefaultAccount", defaultAccount.value);
  store.commit("setConnectWalletDialog", false);
};

const handleAccountsChanged = async (accounts) => {
  console.log("handleAccountsChanged", accounts);
  // this.logout()
  if (window.ethereum) {
    await connectToMetamask();
  }
};

const handleChainChanged = (pChainId) => {
  const chainId = parseInt(pChainId, 16);
  console.log("handleChainChanged", chainId);
  if (chainId !== 56) {
    // this.notifyUnSupportChainId()
    // this.logout()
  }
};

const handleConnect = (connectInfo) => {
  console.log("handleConnect", connectInfo);
};

const handleDisconnect = (code, reason) => {
  console.log("handleDisconnect", code, reason);
};

const notifyUnSupportChainId = () => {};

onMounted(() => {
  // connectToMetamask();
});
</script>

<style scoped></style>
