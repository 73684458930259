<template>
  <div v-if="isLoading">
    <base-spinner></base-spinner>
  </div>
  <div class="w-text mx-auto w-100 header">
    <div
      class="w-100 mx-auto"
      style="max-width: 960px; text-align: right; padding: 1rem 3rem"
    >
      <base-button
        v-if="!defaultAccount"
        @click="showDialog"
        class="btn more-btn wallet"
      >
        connect wallet
      </base-button>
      <base-button v-else @click="closeDialog" class="btn more-btn wallet">
        {{ MathHelper.shortAddress(defaultAccount) }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 576 512"
          style="fill: white"
        >
          <path
            d="M534.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L434.7 224 224 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM192 96c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-53 0-96 43-96 96l0 256c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z"
          />
        </svg>
      </base-button>
    </div>
  </div>
  <div style="height: 64px"></div>

  <b-section
    class="features pt-lg-0 pt-xl-0"
    style="
      width: 100%;
      max-width: 960px;
      margin: 0 auto;
      box-sizing: border-box;
      min-height: calc(100vh - 64px);
      display: flex;
      align-items: center; ;
    "
  >
    <b-container>
      <b-row>
        <b-col col="12" lg="6" class="p-5">
          <div class="d-flex flex-column h-100">
            <div>
              <div class="text-center">
                <img
                  style="width: 150px"
                  :src="require('@/assets/img/logo.jpg')"
                  alt=""
                />
              </div>
              <p class="mt-4 mb-0 text-center text-md-start">
                向 Wildroad International Holdings limited 支付
              </p>
              <p class="title text-center text-md-start">
                {{ price * number }} BUSD
              </p>
              <b-row>
                <b-col cols="12" md="8">
                  <div
                    class="d-flex justify-content-md-start justify-content-center"
                  >
                    <img
                      style="height: 42px; margin-right: 10px"
                      :src="require('@/assets/img/icons/wallet.png')"
                      alt=""
                    />
                    <div class="">
                      <div class="w-text pb-1" style="font-size: 14px">
                        Cordis早鸟优惠团购服务费
                      </div>
                      <p class="m-0" style="font-size: 12px">
                        <span class="">数量 {{ number }}</span>
                        <span class="p-2">,</span>
                        <span class="">单价 {{ price }} BUSD</span>
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="hidden-md" style="flex: 1">
              <div
                style="
                  height: 100%;
                  color: white;
                  font-size: 12px;
                  font-style: italic;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: end;
                  text-align: center;
                "
              >
                <div class="copyright">
                  <span>Powered By WR Travel</span>
                  <span>|</span>
                  <span>条款</span>
                  <span>隐私</span>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col col="12" lg="6" class="p-5">
          <base-form>
            <div
              class="col-12"
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-offset="-10"
            >
              <base-input
                name="email"
                value="邮箱"
                v-model:real-value="userData.email"
                :required="check.email"
              ></base-input>
            </div>
            <div
              class="col-12"
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-offset="-10"
            >
              <base-input
                v-model:real-value="userData.phone"
                :required="check.phone"
                name="phone"
                value="电话"
              ></base-input>
            </div>

            <div
              class="col-12 area"
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-offset="-10"
            >
              <div class="label">支付方式</div>
              <select
                id="billingCountry"
                name="billingCountry"
                autocomplete="billing country"
                aria-label="支付方式"
                class="mt-2"
                readonly
                disabled
              >
                <option value="" disabled="" hidden=""></option>
                <option value="BUSD">BUSD</option>
              </select>
            </div>

            <div
              class="col-12 area"
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-offset="-10"
            >
              <div class="label">国家或地区</div>
              <select
                id="billingCountry"
                name="billingCountry"
                autocomplete="billing country"
                aria-label="国家或地区"
                class="mt-2"
                v-model="userData.area"
              >
                <option value="" disabled="" hidden=""></option>
                <option value="AL">阿尔巴尼亚</option>
                <option value="DZ">阿尔及利亚</option>
                <option value="AF">阿富汗</option>
                <option value="AR">阿根廷</option>
                <option value="AE">阿拉伯联合酋长国</option>
                <option value="AW">阿鲁巴</option>
                <option value="OM">阿曼</option>
                <option value="AZ">阿塞拜疆</option>
                <option value="AC">阿森松岛</option>
                <option value="EG">埃及</option>
                <option value="ET">埃塞俄比亚</option>
                <option value="IE">爱尔兰</option>
                <option value="EE">爱沙尼亚</option>
                <option value="AD">安道尔</option>
                <option value="AO">安哥拉</option>
                <option value="AI">安圭拉</option>
                <option value="AG">安提瓜和巴布达</option>
                <option value="AT">奥地利</option>
                <option value="AX">奥兰群岛</option>
                <option value="AU">澳大利亚</option>
                <option value="BB">巴巴多斯</option>
                <option value="PG">巴布亚新几内亚</option>
                <option value="BS">巴哈马</option>
                <option value="PK">巴基斯坦</option>
                <option value="PY">巴拉圭</option>
                <option value="PS">巴勒斯坦领土</option>
                <option value="BH">巴林</option>
                <option value="PA">巴拿马</option>
                <option value="BR">巴西</option>
                <option value="BY">白俄罗斯</option>
                <option value="BM">百慕大</option>
                <option value="BG">保加利亚</option>
                <option value="MK">北马其顿</option>
                <option value="BJ">贝宁</option>
                <option value="BE">比利时</option>
                <option value="IS">冰岛</option>
                <option value="PR">波多黎各</option>
                <option value="PL">波兰</option>
                <option value="BA">波斯尼亚和黑塞哥维那</option>
                <option value="BO">玻利维亚</option>
                <option value="BZ">伯利兹</option>
                <option value="BW">博茨瓦纳</option>
                <option value="BT">不丹</option>
                <option value="BF">布基纳法索</option>
                <option value="BI">布隆迪</option>
                <option value="BV">布韦岛</option>
                <option value="GQ">赤道几内亚</option>
                <option value="DK">丹麦</option>
                <option value="DE">德国</option>
                <option value="TL">东帝汶</option>
                <option value="TG">多哥</option>
                <option value="DO">多米尼加共和国</option>
                <option value="DM">多米尼克</option>
                <option value="RU">俄罗斯</option>
                <option value="EC">厄瓜多尔</option>
                <option value="ER">厄立特里亚</option>
                <option value="FR">法国</option>
                <option value="FO">法罗群岛</option>
                <option value="PF">法属波利尼西亚</option>
                <option value="GF">法属圭亚那</option>
                <option value="TF">法属南部领地</option>
                <option value="MF">法属圣马丁</option>
                <option value="VA">梵蒂冈</option>
                <option value="PH">菲律宾</option>
                <option value="FJ">斐济</option>
                <option value="FI">芬兰</option>
                <option value="CV">佛得角</option>
                <option value="FK">福克兰群岛</option>
                <option value="GM">冈比亚</option>
                <option value="CG">刚果（布）</option>
                <option value="CD">刚果（金）</option>
                <option value="CO">哥伦比亚</option>
                <option value="CR">哥斯达黎加</option>
                <option value="GD">格林纳达</option>
                <option value="GL">格陵兰</option>
                <option value="GE">格鲁吉亚</option>
                <option value="GG">根西岛</option>
                <option value="GP">瓜德罗普</option>
                <option value="GU">关岛</option>
                <option value="GY">圭亚那</option>
                <option value="KZ">哈萨克斯坦</option>
                <option value="HT">海地</option>
                <option value="KR">韩国</option>
                <option value="NL">荷兰</option>
                <option value="BQ">荷属加勒比区</option>
                <option value="SX">荷属圣马丁</option>
                <option value="ME">黑山</option>
                <option value="HN">洪都拉斯</option>
                <option value="KI">基里巴斯</option>
                <option value="DJ">吉布提</option>
                <option value="KG">吉尔吉斯斯坦</option>
                <option value="GN">几内亚</option>
                <option value="GW">几内亚比绍</option>
                <option value="CA">加拿大</option>
                <option value="GH">加纳</option>
                <option value="GA">加蓬</option>
                <option value="KH">柬埔寨</option>
                <option value="CZ">捷克</option>
                <option value="ZW">津巴布韦</option>
                <option value="CM">喀麦隆</option>
                <option value="QA">卡塔尔</option>
                <option value="KY">开曼群岛</option>
                <option value="KM">科摩罗</option>
                <option value="XK">科索沃</option>
                <option value="CI">科特迪瓦</option>
                <option value="KW">科威特</option>
                <option value="HR">克罗地亚</option>
                <option value="KE">肯尼亚</option>
                <option value="CK">库克群岛</option>
                <option value="CW">库拉索</option>
                <option value="LV">拉脱维亚</option>
                <option value="LS">莱索托</option>
                <option value="LA">老挝</option>
                <option value="LB">黎巴嫩</option>
                <option value="LT">立陶宛</option>
                <option value="LR">利比里亚</option>
                <option value="LY">利比亚</option>
                <option value="LI">列支敦士登</option>
                <option value="RE">留尼汪</option>
                <option value="LU">卢森堡</option>
                <option value="RW">卢旺达</option>
                <option value="RO">罗马尼亚</option>
                <option value="MG">马达加斯加</option>
                <option value="IM">马恩岛</option>
                <option value="MV">马尔代夫</option>
                <option value="MT">马耳他</option>
                <option value="MW">马拉维</option>
                <option value="MY">马来西亚</option>
                <option value="ML">马里</option>
                <option value="MQ">马提尼克</option>
                <option value="YT">马约特</option>
                <option value="MU">毛里求斯</option>
                <option value="MR">毛里塔尼亚</option>
                <option value="US">美国</option>
                <option value="MN">蒙古</option>
                <option value="MS">蒙特塞拉特</option>
                <option value="BD">孟加拉国</option>
                <option value="PE">秘鲁</option>
                <option value="MM">缅甸</option>
                <option value="MD">摩尔多瓦</option>
                <option value="MA">摩洛哥</option>
                <option value="MC">摩纳哥</option>
                <option value="MZ">莫桑比克</option>
                <option value="MX">墨西哥</option>
                <option value="NA">纳米比亚</option>
                <option value="ZA">南非</option>
                <option value="AQ">南极洲</option>
                <option value="GS">南乔治亚和南桑威奇群岛</option>
                <option value="SS">南苏丹</option>
                <option value="NR">瑙鲁</option>
                <option value="NI">尼加拉瓜</option>
                <option value="NP">尼泊尔</option>
                <option value="NE">尼日尔</option>
                <option value="NG">尼日利亚</option>
                <option value="NU">纽埃</option>
                <option value="NO">挪威</option>
                <option value="PN">皮特凯恩群岛</option>
                <option value="PT">葡萄牙</option>
                <option value="JP">日本</option>
                <option value="SE">瑞典</option>
                <option value="CH">瑞士</option>
                <option value="SV">萨尔瓦多</option>
                <option value="WS">萨摩亚</option>
                <option value="RS">塞尔维亚</option>
                <option value="SL">塞拉利昂</option>
                <option value="SN">塞内加尔</option>
                <option value="CY">塞浦路斯</option>
                <option value="SC">塞舌尔</option>
                <option value="SA">沙特阿拉伯</option>
                <option value="BL">圣巴泰勒米</option>
                <option value="ST">圣多美和普林西比</option>
                <option value="SH">圣赫勒拿</option>
                <option value="KN">圣基茨和尼维斯</option>
                <option value="LC">圣卢西亚</option>
                <option value="SM">圣马力诺</option>
                <option value="PM">圣皮埃尔和密克隆群岛</option>
                <option value="VC">圣文森特和格林纳丁斯</option>
                <option value="LK">斯里兰卡</option>
                <option value="SK">斯洛伐克</option>
                <option value="SI">斯洛文尼亚</option>
                <option value="SJ">斯瓦尔巴和扬马延</option>
                <option value="SZ">斯威士兰</option>
                <option value="SR">苏里南</option>
                <option value="SB">所罗门群岛</option>
                <option value="SO">索马里</option>
                <option value="TJ">塔吉克斯坦</option>
                <option value="TW">台湾</option>
                <option value="TH">泰国</option>
                <option value="TZ">坦桑尼亚</option>
                <option value="TO">汤加</option>
                <option value="TC">特克斯和凯科斯群岛</option>
                <option value="TA">特里斯坦-达库尼亚群岛</option>
                <option value="TT">特立尼达和多巴哥</option>
                <option value="TN">突尼斯</option>
                <option value="TV">图瓦卢</option>
                <option value="TR">土耳其</option>
                <option value="TM">土库曼斯坦</option>
                <option value="TK">托克劳</option>
                <option value="WF">瓦利斯和富图纳</option>
                <option value="VU">瓦努阿图</option>
                <option value="GT">危地马拉</option>
                <option value="VE">委内瑞拉</option>
                <option value="BN">文莱</option>
                <option value="UG">乌干达</option>
                <option value="UA">乌克兰</option>
                <option value="UY">乌拉圭</option>
                <option value="UZ">乌兹别克斯坦</option>
                <option value="ES">西班牙</option>
                <option value="EH">西撒哈拉</option>
                <option value="GR">希腊</option>
                <option value="SG">新加坡</option>
                <option value="NC">新喀里多尼亚</option>
                <option value="NZ">新西兰</option>
                <option value="HU">匈牙利</option>
                <option value="JM">牙买加</option>
                <option value="AM">亚美尼亚</option>
                <option value="YE">也门</option>
                <option value="IQ">伊拉克</option>
                <option value="IL">以色列</option>
                <option value="IT">意大利</option>
                <option value="IN">印度</option>
                <option value="ID">印度尼西亚</option>
                <option value="GB">英国</option>
                <option value="VG">英属维尔京群岛</option>
                <option value="IO">英属印度洋领地</option>
                <option value="JO">约旦</option>
                <option value="VN">越南</option>
                <option value="ZM">赞比亚</option>
                <option value="JE">泽西岛</option>
                <option value="TD">乍得</option>
                <option value="GI">直布罗陀</option>
                <option value="CL">智利</option>
                <option value="CF">中非共和国</option>
                <option value="CN">中国</option>
                <option value="MO">中国澳门特别行政区</option>
                <option value="HK">中国香港特别行政区</option>
              </select>
            </div>

            <div
              class="col-12"
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-offset="-10"
            >
              <base-button
                v-if="!defaultAccount"
                mode="btn more-btn me-3"
                class="w-100"
                @click="showDialog"
              >
                Connect Wallet
              </base-button>

              <base-button
                v-else-if="isApprove"
                mode="btn more-btn me-3"
                class="w-100"
                @click.stop="pay"
                :disabled="payLoading"
              >
                <span
                  v-if="payLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Pay
              </base-button>
              <base-button
                @click="approve"
                v-else
                mode="btn more-btn me-3"
                class="w-100"
                :disabled="approveLoading"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-if="approveLoading"
                ></span>
                Approve BUSD
              </base-button>
            </div>
            <div class="w-100 mt-5 hidden-min-md">
              <div class="copyright mx-auto">
                <span>Powered By WR Travel</span>
                <span>|</span>
                <span>条款</span>
                <span>隐私</span>
              </div>
            </div>
          </base-form>
        </b-col>
      </b-row>
    </b-container>
  </b-section>
  <div v-if="dialogVisible" @click="closeDialog" class="mask">
    <div
      @click.stop
      data-aos="fade-down"
      data-aos-delay="200"
      data-aos-duration="500"
      data-aos-once="true"
      data-aos-offset="-100"
      class="p-3 card"
    >
      <wallet />
    </div>
  </div>
</template>

<script setup>
import BRow from "@/components/Helper/BRow";
import BaseInput from "@/components/local/UI/Form/BaseInput";
import BaseForm from "@/components/local/UI/Form/Form";
import BContainer from "@/components/Helper/BContainer";
import Wallet from "@/pages/Wallet";
import { onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import MathHelper from "@/utils/MathHelper";

const store = useStore();
const isLoading = ref(true);
const dialogVisible = ref(false);
const defaultAccount = ref("");
const price = ref(1);
const number = ref(10);

const userData = reactive({ phone: "", email: "", area: "AL" });
const check = ref({ phone: false, email: false });

const lock = () => {
  document.documentElement.style.overflow = "hidden";
};
const unlock = () => {
  document.documentElement.style.overflow = "auto";
};
const closeDialog = () => {
  dialogVisible.value = false;
  store.commit("setConnectWalletDialog", false);
  store.commit("setDefaultAccount", "");
};

const showDialog = () => {
  store.commit("setConnectWalletDialog", true);
};

watch(
  () => store.state.connectWalletDialog,
  async (value, oldValue) => {
    console.log(value, oldValue);
    dialogVisible.value = value;
    value ? lock() : unlock();
  }
);

watch(
  () => store.state.defaultAccount,
  () => {
    defaultAccount.value = store.state.defaultAccount;
    if (defaultAccount.value) {
      allowanceOf();
    }
  }
);

onMounted(() => {
  setTimeout(() => {
    isLoading.value = false;
  }, 500);
  if (store.state.defaultAccount) {
    defaultAccount.value = store.state.defaultAccount;
    allowanceOf();
  }
});

//合约交互
import { getBep20Contract, getPayContract } from "@/utils/ContractHelpers";
import { PayAddr } from "@/utils/ContractAddress";
import tokens from "@/utils/tokens";
import { ethers } from "ethers";

const isApprove = ref(false);
const approveLoading = ref(false);
const payLoading = ref(false);
const busd = tokens.busd;

const allowanceOf = async () => {
  const tokenContract = getBep20Contract(busd.address);
  const allowance = await tokenContract.allowance(
    defaultAccount.value,
    PayAddr
  );
  isApprove.value = allowance.gt(0);
};

const approve = async () => {
  approveLoading.value = true;
  try {
    const tokenContract = getBep20Contract(busd.address);
    const result = await tokenContract.approve(
      PayAddr,
      ethers.constants.MaxUint256
    );
    await result.wait();
    await allowanceOf();
  } catch (error) {
    console.log("approve error", error);
  } finally {
    approveLoading.value = false;
  }
};
watch([() => userData.email, () => userData.phone], (value, oldValue) => {
  console.log(value, oldValue);
  if (value[0]) {
    check.value.email = false;
  }
  if (value[1]) {
    check.value.phone = false;
  }
});
const pay = async () => {
  console.log("email", userData);
  if (!userData.phone) {
    check.value.phone = true;
  }
  if (!userData.email) {
    check.value.email = true;
  }
  if (!userData.email || !userData.email) {
    return;
  }

  try {
    payLoading.value = true;
    const payContract = getPayContract();

    const amount = ethers.utils.parseUnits(
      (number.value * price.value).toString(),
      busd.decimals
    );
    const orderId = Math.round(Math.random() * 10e10);
    console.log(orderId);
    const result = await payContract.stake(orderId, amount);
    await result.wait();
  } catch (e) {
    console.error("payment error", e);
    // const message = (e.data && e.data.message) ? e.data.message : e.message
    // this.$message.error(message)
  } finally {
    payLoading.value = false;
  }
};
</script>

<style scoped>
.mask {
  margin-top: 64px;
  width: 100%;
  height: calc(100vh - 64px);
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 999999;
  color: white;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.mask .card {
  width: 350px;
  max-width: 90%;
  background: #0e1226;
  margin: 10px auto;
  border-radius: 10px;
}

.header {
  position: fixed;
  background: #010415;
  height: 64px;
  z-index: 99999;
  box-sizing: border-box;
}

.sub-title {
  font-weight: 500;
  font-size: 16px;
}

.title {
  font-family: "Poppins", sans-serif;
  color: #fff;
  line-height: 1.4;
  font-weight: 500;
  font-size: 30px;
}

.more-btn {
  font-size: 16px;
  font-style: italic;
  border: 2px solid transparent;
}

.more-btn:active,
.more-btn:focus,
.more-btn:focus-visible,
.more-btn:hover {
  border: 2px solid #fff;
}

.more-btn.wallet {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
}

.more-btn.wallet:active,
.more-btn.wallet:focus,
.more-btn.wallet:focus-visible,
.more-btn.wallet:hover {
  border: 2px solid #fff;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
}

.area {
  height: 85px;
}

.area .label {
  color: #fff;
  font-style: italic;
  font-size: 12px;
  font-weight: normal;
  margin-top: -12px;
  margin-bottom: 5px;
}

.area select {
  width: 100%;
  border: 2px solid #ffffff33;
  appearance: none;
  height: 40px;
  background: #03091f;
  padding: 0 20px;
  border-radius: 8px;
  font-size: 12px;
  font-style: italic;
  color: #fff;
  outline-color: white;
  box-shadow: none;
  transition: all 0.2s;
}

.area select:hover,
.area select:focus-visible,
.area select:focus,
.area select:visited,
.area select:focus-within,
.area select:active {
  appearance: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari 和 Chrome */
  outline: none !important;
  border: 2px solid #ced4da !important;
}

.copyright {
  width: 200px;
  font-style: italic;
  font-size: 12px;
  color: #ffffff80;
  display: flex;
  justify-content: space-between;
}
</style>
